/**
 * ! Change Details by your component name
 */

import React, { useState, useEffect } from "react";
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
import CustomFooter from "../common/shared/custom-footer.js";
import CompaniesSelection from "../common/specific/companies-selection.js";
const Details = () => {
	const componentId = "Details-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}

	async function loadAll() {
		document.title = t("index:App.title");
		setLoaded(true);
	}

	function onError(error) {
		if (process.env.REACT_APP_ENV == "development") {
			handleShowToast("error", t("error", { ns: "common" }), `Home ${error}`);
		}
	}

	function setHeadMenu() {
		return (
			<nav className="navbar navbar-expand-lg navbar-dark Mineral navbar-shadow fixed-top">
				<div className="container-fluid">
					<a className="navbar-brand container-inline" href="/">
						<img
							className="d-inline-block align-text-top top-bot-img-index"
							src={require("../../rits-package/img/logos/AO2-dark.png")}
							alt=""
							height="70"
						/>
						<div className="row">
							<center>
								{" "}
								<h5 className="text-blue-logo-dark">
									&nbsp;<span className="trn">{t("index:App.title")}</span>
								</h5>
								<i className="font-md">
									&nbsp;<span className="trn">{t("index:App.subtitle")}</span>
								</i>
							</center>
						</div>
					</a>
					<button
						className="navbar-toggler m-4 mb-0 mt-0"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarText"
						aria-controls="navbarText"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						{" "}
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse p-2" id="navbarText">
						<ul
							className="navbar-nav me-auto mb-2 mb-lg-0 p-4 pb-0 pt-0"
							style={{ left: "15px !important", marginLeft: "0 !important" }}
						>
							<li className="animate pop">
								<a className="nav-link active trn" href="#menu">
									{t("index:App.Nav1")}
								</a>
							</li>
							<li className="animate pop delay-1">
								<a className="nav-link active trn" href="#clickncollect">
									{t("index:App.Nav2")}
								</a>
							</li>
							<li className="animate pop delay-2">
								<a className="nav-link active trn" href="#connect">
									{t("index:Connectivity")}
								</a>
							</li>
							<li className="animate pop delay-4">
								<a className="nav-link active trn" href="#prices">
									{t("index:App.Nav5")}
								</a>
							</li>
						</ul>
						<a
							className=" btn btn-outline-logo-dark trn"
							href="https://menu.app-o2.com/"
							id="login-btn"
						>
							{t("index:App.subscribe")}
						</a>
					</div>
				</div>
			</nav>
		);
	}

	function setContentTop() {
		return (
			<>
				<div className="mb-4" style={{ height: "80px" }}></div>
				<div className="row">
					<div className="mb-2"> </div>
					<div className="mb-2"></div>
					<div className="mb-2"> </div>
					<center className="animate glow">
						<img
							className="img-md"
							src={require("../../rits-package/img/menu/logos/logo.png")}
						/>
						<h1 className="txt-Slate trn">{t("index:App.slogan")}</h1>
					</center>
				</div>
				<div className="mb-2"> </div>
			</>
		);
	}

	function setContent1() {
		return (
			<>
				<div className="row">
					<div className="col-12">
						<div className="card mt-4 mb-4">
							<div className="card-header bg-primary-gradient border-rounded">
								<h3>{t("common:join-us")}</h3>
							</div>
						</div>

					</div>
				</div>
				<div className="row">
					<center>
						<h1 className="txt-Ochre">{t("index:Price.join-us")} </h1>
						<h1 className="txt-Ochre">{t("index:Price.join")} </h1>
					</center>
				</div>
				<div className="mb-4"></div>
				<div
					className="carousel slide carousel-fade border-rounded navbar-shadow animate blur delay-1"
					data-bs-ride="carousel"
					id="carousel1"
				>
					<div className="carousel-indicators carousel-indicators-bottom">
						<button
							className="active"
							type="button"
							data-bs-target="#carousel1"
							data-bs-slide-to="0"
							aria-current="true"
							aria-label="Slide 1"
						></button>
						<button
							type="button"
							data-bs-target="#carousel1"
							data-bs-slide-to="1"
							aria-label="Slide 2"
						></button>
						<button
							type="button"
							data-bs-target="#carousel1"
							data-bs-slide-to="2"
							aria-label="Slide 3"
						></button>
					</div>
					<div className="carousel-inner">
						<div className="carousel-item active  ">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width  border-rounded"
									src={require("../../rits-package/img/menu/index/slide1.png")}
								/>
							</a>
							<div className="carousel-caption card carousel-content">
								<h4 className="trn">{t("index:Control")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:ControlDetail")}
								</h5>
							</div>
						</div>
						<div className="carousel-item">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width  border-rounded"
									src={require("../../rits-package/img/menu/index/slide2.png")}
								/></a>
							<div className="carousel-caption card  carousel-content">
								<h4 className="trn">{t("index:Efficient")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:EfficientDetail")}
								</h5>
							</div>
						</div>
						<div className="carousel-item">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width border-rounded"
									src={require("../../rits-package/img/menu/index/slide3.png")}
								/></a>
							<div className="carousel-caption card carousel-content">
								<h4 className="trn">{t("index:Visibility")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:VisibilityDetail")}
								</h5>
							</div>
						</div>
					</div>
					<button
						className="carousel-control-prev d-none d-md-block"
						type="button"
						data-bs-target="#carousel1"
						data-bs-slide="prev"
					>
						<span
							className="carousel-control-prev-icon"
							aria-hidden="true"
						></span>
						<span className="visually-hidden">{t("index:Previous")}</span>
					</button>
					<button
						className="carousel-control-next d-none d-md-block"
						type="button"
						data-bs-target="#carousel1"
						data-bs-slide="next"
					>
						<span
							className="carousel-control-next-icon"
							aria-hidden="true"
						></span>
						<span className="visually-hidden">{t("index:Next")} </span>
					</button>
				</div>
			</>
		);
	}

	function setContent2() {
		return (
			<>
				<div className="mb-4"></div>
				<div
					className="carousel slide carousel-fade border-rounded navbar-shadow animate blur delay-3"
					data-bs-ride="carousel"
					id="carousel2"
				>
					<div className="carousel-indicators carousel-indicators-bottom">
						<button
							className="active"
							type="button"
							data-bs-target="#carousel2"
							data-bs-slide-to="0"
							aria-current="true"
							aria-label="Slide 1"
						></button>
						<button
							type="button"
							data-bs-target="#carousel2"
							data-bs-slide-to="1"
							aria-label="Slide 2"
						></button>
						<button
							type="button"
							data-bs-target="#carousel2"
							data-bs-slide-to="2"
							aria-label="Slide 3"
						></button>
					</div>
					<div className="carousel-inner">
						<div className="carousel-item active  ">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width border-rounded"
									src={require("../../rits-package/img/menu/index/slide4.png")}
								/>
							</a>
							<div className="carousel-caption card carousel-content">
								<h4 className="trn">{t("index:Profitability")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:ProfitabilityDetail")}
								</h5>
							</div>
						</div>
						<div className="carousel-item">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width border-rounded"
									src={require("../../rits-package/img/menu/index/slide5.png")}
								/></a>
							<div className="carousel-caption card  carousel-content">
								<h4 className="trn">{t("index:Accessibility")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:AccessibilityDetail")}
								</h5>
							</div>
						</div>
						<div className="carousel-item">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width border-rounded"
									src={require("../../rits-package/img/menu/index/slide6.png")}
								/></a>
							<div className="carousel-caption card carousel-content">
								<h4 className="trn">{t("index:Connectivity")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:ConnectivityDetail")}
								</h5>
							</div>
						</div>
					</div>
					<button
						className="carousel-control-prev d-none d-md-block"
						type="button"
						data-bs-target="#carousel2"
						data-bs-slide="prev"
					>
						<span
							className="carousel-control-prev-icon"
							aria-hidden="true"
						></span>
						<span className="visually-hidden">{t("index:Previous")}</span>
					</button>
					<button
						className="carousel-control-next d-none d-md-block"
						type="button"
						data-bs-target="#carousel2"
						data-bs-slide="next"
					>
						<span
							className="carousel-control-next-icon"
							aria-hidden="true"
						></span>
						<span className="visually-hidden">{t("index:Next")}</span>
					</button>
				</div>

			</>
		);
	}
	function setContent3() {
		return (
			<>
				<div className="mb-4"></div>
				<div
					className="carousel slide carousel-fade border-rounded navbar-shadow animate blur delay-5"
					data-bs-ride="carousel"
					id="carousel3"
				>
					<div className="carousel-indicators carousel-indicators-bottom">
						<button
							className="active"
							type="button"
							data-bs-target="#carousel3"
							data-bs-slide-to="0"
							aria-current="true"
							aria-label="Slide 1"
						></button>
						<button
							type="button"
							data-bs-target="#carousel3"
							data-bs-slide-to="1"
							aria-label="Slide 2"
						></button>
					</div>
					<div className="carousel-inner">
						<div className="carousel-item active  ">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width border-rounded"
									src={require("../../rits-package/img/menu/index/slide7.png")}
								/></a>
							<div className="carousel-caption card carousel-content">
								<h4 className="trn">{t("index:Freedom")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:FreedomDetail")}
								</h5>
							</div>
						</div>
						<div className="carousel-item">
							<a href="https://menu.app-o2.com/" target="_blank" rel="noreferrer">
								<img
									className="img-full-width border-rounded"
									src={require("../../rits-package/img/menu/index/slide8.png")}
								/></a>
							<div className="carousel-caption card  carousel-content">
								<h4 className="trn">{t("index:Innovation")}</h4>
								<h5 className="carousel-content-inner trn">
									{t("index:InnovationDetail")}
								</h5>
							</div>
						</div>
					</div>
					<button
						className="carousel-control-prev d-none d-md-block"
						type="button"
						data-bs-target="#carousel3"
						data-bs-slide="prev"
					>
						<span
							className="carousel-control-prev-icon"
							aria-hidden="true"
						></span>
						<span className="visually-hidden">{t("index:Previous")}</span>
					</button>
					<button
						className="carousel-control-next d-none d-md-block"
						type="button"
						data-bs-target="#carousel3"
						data-bs-slide="next"
					>
						<span
							className="carousel-control-next-icon"
							aria-hidden="true"
						></span>
						<span className="visually-hidden">{t("index:Next")}</span>
					</button>
				</div>

			</>
		);
	}
	function setContent4() {
		return (
			<>
				<div className="mb-4"></div>
				<div className="row">
					<div className="col-sm-12">
						<div className="card animate blur delay-10 card-no-padding-margin">
							<div className="card-header Mineral p-0 m-0">
								<div className=" container-inline-right">
									<div className="text-center">
										<h3
											className="card-title text-white text-end "
											style={{ marginRight: "10px" }}
										>
											{" "}
											<span className="trn">{t("index:Prices.title")}</span>
										</h3>
									</div>
									<div>
										{" "}
										<img
											className="img-md right-inline-card-img"
											src={require("../../rits-package/img/menu/common/card3.png")}
										/>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="col-sm-12">
									<ul>
										<li>
											<h5 className="trn">{t("index:Prices.section1")}</h5>
											<div className="mb-4"> </div>
										</li>
										<li>
											<p className="trn">{t("index:Prices.section2")}</p>
											<div className="mb-4"> </div>
										</li>
										<li>
											<b className="trn">{t("index:Prices.section4")}</b>
											<div className="mb-4"> </div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="mb-4"></div>
				<div className="row">
					<div className="mb-4"></div>
					<center>
						<h1 className="txt-Ochre">{t("index:Price.join-us")} </h1>
						<h1 className="txt-Ochre">{t("index:Price.join")} </h1>
					</center>
					<div className="mb-4"></div>
					<a
						className="btn btn-Ochre m-2 trn"
						href="https://menu.app-o2.com/"
						style={{
							position: "fixed",
							bottom: "50px",
							left: "50%",
							transform: "translateX(-155px)",
							width: "300px",
							height: "50px",
							zIndex: "1000",
							alignContent: "center",
							justifyContent: "center",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<h4><i className="fa fa-face-laugh-wink"></i> {t("index:Price.subscribe")}</h4>
					</a>
					<div className="mb-4"></div>
					<div className="mb-4"></div>
					<div className="mb-4"></div>
				</div>





			</>
		);
	}

	function setContentCompanies() {
		return (
			<CompaniesSelection
				properties={{
					pageName: t("common:registered-companies"),
					trNamespace: "index",
				}}
			></CompaniesSelection>
		)
	}

	return !loaded ? null : (
		<div key={componentId}>
			<div className="mb-4">{setHeadMenu()}</div>
			<section className="container-fluid">
				<div className="m-4" style={{ top: "100px !important" }}>
					<div id="menu" className="mb-2 mt-2"></div>
					{setContentTop()}
					{setContentCompanies()}
					{setContent1()}
					<div id="clickncollect" className="mb-2 mt-2"></div>
					{setContent2()}
					<div id="connect" className="mb-2 mt-2"></div>
					{setContent3()}
					<div id="prices" className="mb-2 mt-2"></div>
					{setContent4()}
					<div className="mb-4"></div>
				</div>
			</section>
			<div className="app-separator-large"></div>
			<CustomFooter></CustomFooter>
		</div>
	);
};

export default Details;
